<template>
  <BModal
    id="modal-give-access"
    ref="modal-give-access"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
  >
    <div
      class="p-[32px]"
    >
      <div>
        <div class="flex justify-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt="Komerce"
            class="h-[100px]"
          >
        </div>
        <div class="text-[#333333] mt-[32px] text-center font-bold text-[24px]">
          Are you sure give this user live access?
        </div>
        <div
          class="text-[#626262] text-center leading-[24px] text-[16px] mt-[8px]"
        >
          Before giving Production access to the Collaborator, make sure the UAT document has been filled in and verified first according to the expected criteria.
        </div>
      </div>
      <div class="flex gap-[10px] mt-[32px]">
        <BButton
          variant="outline-primary"
          class="w-full"
          @click="$bvModal.hide('modal-give-access')"
        >
          Cancel
        </BButton>
        <BButton
          variant="primary"
          class="w-full"
          @click="giveAccess()"
        >
          Process
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { openApiAxiosIns } from '@/libs/axios'

export default {
  props: {
    getDetail: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      emailUser: '',
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    const email = id.replace(/^user=/, '')
    this.emailUser = atob(email)
  },
  methods: {
    async giveAccess() {
      const params = {
        email: this.emailUser,
        app: 'openapi',
      }
      try {
        const url = '/user/api/v1/admin/generate-api-key'
        const res = await openApiAxiosIns.put(url, params)
        const { data } = res.data

        this.$toast_success({ message: 'Your collaborator access has successfully change ' })
        this.$bvModal.hide('modal-give-access')
        this.getDetail()
      } catch (error) {
        this.$toast_error({ message: 'Oops, something went wrong. Please try again later.' })
      }
    },
  },
}
</script>
