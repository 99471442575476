export const fieldsCollaboratorTable = [
  {
    key: 'name',
    label: 'Collaborator Name',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'package',
    label: 'Package',
    thClass: 'font-bold capitalize text-black',
    tdClass: 'px-0 d-flex justify-content-center',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '150px',
    },
  },
  {
    key: 'phone_number',
    label: 'Phone Number',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'address',
    label: 'Address',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'api_access',
    label: 'API Access',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'detail',
    label: '',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]

export const fieldExpeditionService = [
  {
    key: 'shipping_name',
    label: 'Expedition',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'regular',
    label: 'Regular',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'cargo',
    label: 'Cargo',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'lite',
    label: 'Lite',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'flat',
    label: 'Flat',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
]

export const black = 'gjhj'
