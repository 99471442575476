<template>
  <div>
    <BCard class="border">
      <div class="d-flex justify-content-between flex-column flex-lg-row gap-5">
        <span class="d-flex flex-column col-12 col-lg-4 text-black">Payment Setting
          <small class="text-secondary">Payment options available for users</small>
        </span>
        <div class="col-12 col-lg-8 d-flex gap-5">
          <div
            v-for="(item, index) in paymentMethodList"
            :key="index"
            class="d-flex flex-row gap-3 checkbox-payment p-2 rounded"
          >
            <b-form-checkbox
              v-model="item.onCheck"
              class="custom-control-primary mb-1"
              @change="choosePaymentMethod(item)"
            >
              <span class="text-black">{{ item.label }}</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </BCard>
    <BCard class="border mt-3">
      <span class="text-black font-[600] ml-1">Expedition Service</span>
      <BTable
        id="scroll"
        ref="scroll"
        :items="itemsExpeditionService"
        :fields="fieldExpeditionService"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        sticky-header="500px"
        class="mt-1"
      >
        <template #cell(shipping_name)="data">
          <div class="d-flex align-items-center gap-5">
            {{ data.item.shipping_name }}
          </div>
        </template>
        <template #cell(regular)="data">
          <div class="d-flex align-items-center gap-5">
            <b-form-checkbox
              :checked="data.item.services.regular === 1"
              :disabled="data.item.services.regular === 2"
              @change="onCheckChange('regular', data.item.services, data.item.shipping_name)"
            />
          </div>
        </template>
        <template #cell(cargo)="data">
          <div class="d-flex align-items-center gap-5">
            <b-form-checkbox
              :checked="data.item.services.cargo === 1"
              :disabled="data.item.services.cargo === 2"
              @change="onCheckChange('cargo', data.item.services, data.item.shipping_name)"
            />
          </div>
        </template>
        <template #cell(lite)="data">
          <div class="d-flex align-items-center gap-5">
            <b-form-checkbox
              :checked="data.item.services.lite === 1"
              :disabled="data.item.services.lite === 2"
              @change="onCheckChange('lite', data.item.services, data.item.shipping_name)"
            />
          </div>
        </template>
        <template #cell(flat)="data">
          <div class="d-flex align-items-center gap-5">
            <b-form-checkbox
              :checked="data.item.services.flat === 1"
              :disabled="data.item.services.flat === 2"
              @change="onCheckChange('flat', data.item.services, data.item.shipping_name)"
            />
          </div>
        </template>
      </BTable>

    </BCard>
    <hr>
    <div class="text-end">
      <BButton
        variant="outline-secondary"
        class="mr-1"
        style="width: 100px;"
        @click="cancelChange()"
      >
        Cancel
      </BButton>
      <BButton
        :variant="isDisabledSubmit ? 'outline-secondary' : 'primary'"
        style="width: 100px;"
        :disabled="isDisabledSubmit"
        @click="saveChange()"
      >
        Save
      </BButton>
    </div>
  </div>
</template>

<script>
import { openApiAxiosIns } from '@/libs/axios'
import { fieldExpeditionService } from '../config'

export default {
  props: {
    emailUser: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: [],
      fieldExpeditionService,
      itemsExpeditionService: [],
      paymentMethod: [],
      filteredPaymentMethod: '',
      paymentMethodList: [
        {
          label: 'COD (Cash On Delivery)',
          value: 'cod',
          onCheck: false,
        },
        {
          label: 'Bank Transfer',
          value: 'bank_transfer',
          onCheck: false,
        },
      ],
      paramsUpdatePayment: {
        is_cod: 0,
        is_bank_transfer: 0,
      },
      paramsUpdateExpedition: [],
      isDisabledSubmit: true,
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    const email = id.replace(/^user=/, '')
    this.emailUser = atob(email)
    this.getOrderPayment()
    this.getExpedtion()
  },
  methods: {
    async getOrderPayment() {
      try {
        const url = `/user/api/v1/admin/payment-setting/users/${this.emailUser}`
        const res = await openApiAxiosIns.get(url)
        const { data } = res.data

        this.paymentMethodList.forEach((method, index) => {
          // eslint-disable-next-line no-param-reassign
          method.onCheck = index === 0 ? data.is_cod === 1 : data.is_bank_transfer === 1
        })

        this.paymentMethod = []
        if (data.is_cod === 1) {
          this.paymentMethod.push('cod')
        }
        if (data.is_bank_transfer === 1) {
          this.paymentMethod.push('bank_transfer')
        }

        this.updateParams()
      } catch (error) {
        this.$toast_error({ message: 'Failed to get data' })
      }
    },

    choosePaymentMethod(item) {
      this.isDisabledSubmit = false
      const isChecked = item.onCheck
      const methodIndex = this.paymentMethod.indexOf(item.value)

      if (isChecked && methodIndex === -1) {
        this.paymentMethod.push(item.value)
      } else if (!isChecked && methodIndex !== -1) {
        this.paymentMethod.splice(methodIndex, 1)
      }

      this.updateParams()
    },

    updateParams() {
      this.paramsUpdatePayment = {
        is_cod: this.paymentMethod.includes('cod') ? 1 : 0,
        is_bank_transfer: this.paymentMethod.includes('bank_transfer') ? 1 : 0,
      }
    },
    async updateOrderPayment() {
      const url = `/user/api/v1/admin/payment-setting/users/${this.emailUser}`
      try {
        await openApiAxiosIns.put(url, this.paramsUpdatePayment)
        await this.getOrderPayment()
      } catch {
        this.$toast_error({ message: "Failed to update collaborator's data" })
      }
    },
    async getExpedtion() {
      try {
        const url = `/user/api/v1/admin/expedition-setting/users/${this.emailUser}`
        const res = await openApiAxiosIns.get(url)
        const { data } = res.data
        this.itemsExpeditionService = data
        this.paramsUpdateExpedition = data.map(item => ({
          shipping_name: item.shipping_name,
          services: { ...item.services },
        }))
      } catch (error) {
        console.log(error)
      }
    },
    async updateExpedtion() {
      const params = {
        data: this.paramsUpdateExpedition,
      }
      try {
        const url = `/user/api/v1/admin/expedition-setting/users/${this.emailUser}`
        const res = await openApiAxiosIns.put(url, params)
        const { data } = res.data
        this.itemsExpeditionService = data
        this.getExpedtion()
      } catch (error) {
        this.$toast_error({ message: 'Failed to get data' })
      }
    },
    onCheckChange(serviceKey, services, shipping) {
      this.isDisabledSubmit = false
      // eslint-disable-next-line no-param-reassign
      services[serviceKey] = services[serviceKey] === 1 ? 0 : 1

      const expeditionIndex = this.paramsUpdateExpedition.findIndex(
        item => item.shipping_name === shipping,
      )

      if (expeditionIndex !== -1) {
        this.paramsUpdateExpedition[expeditionIndex].services[serviceKey] = services[serviceKey]
      } else {
        this.paramsUpdateExpedition.push({
          shipping_name: shipping,
          services: {
            regular: serviceKey === 'regular' ? services[serviceKey] : 0,
            cargo: serviceKey === 'cargo' ? services[serviceKey] : 0,
            flat: serviceKey === 'flat' ? services[serviceKey] : 0,
            lite: serviceKey === 'lite' ? services[serviceKey] : 0,
          },
        })
      }
    },
    cancelChange() {
      this.getOrderPayment()
      this.getExpedtion()
      this.isDisabledSubmit = true
    },
    saveChange() {
      this.updateOrderPayment()
      this.updateExpedtion()
      this.$toast_success({ message: "Success to update collaborator's data" })
      this.isDisabledSubmit = true
    },
  },
}
</script>

<style scoped>
.checkbox-payment {
    background-color: #ffebe9;
    width: 250px;
    height: 60px;
}
</style>
